import React from "react"

import { ReactComponent as BusinessLogicIcon } from "@src/icons/business_logic_purple.svg"
import { ReactComponent as WebhooksIcon } from "@src/icons/webhooks_purple.svg"
import { ReactComponent as PlaygroundIcon } from "@src/icons/playground_purple.svg"
import { ReactComponent as UnifiedDeploymentsIcon } from "@src/icons/unified_deployments_purple.svg"
import { ReactComponent as CraftedCMSIcon } from "@src/icons/crafted_cms_purple.svg"
import { ReactComponent as I18nIcon } from "@src/icons/i18n_purple.svg"
import { ReactComponent as EnterpriseIllustration } from "@src/icons/enterprise.dark.svg"

import Layout from "@src/components/Layout"
import SplashWithIllustration from "@src/components/SplashWithIllustration"
import Cards from "@src/components/Cards"
import Callout from "@src/components/Callout"

import * as styles from "./Enterprise.module.scss"
import FeatureGrid from "@src/components/FeatureGrid"
import Section from "@src/components/Section"

const Enterprise = ({ location }) => {
  return (
    <Layout
      title="Enterprise"
      description="Focus on your core enterprise and avoid the maintenance grind. Canonic is enterprise ready and can save you up to hundreds of hours of development a week."
      url="https://canonic.dev/enterprise"
      location={location}
    >
      <SplashWithIllustration
        isWhite
        illustrationComponent={<EnterpriseIllustration />}
        title="Focus on your core enterprise and avoid the maintenance grind."
        subtitle="Canonic is enterprise ready and can save you up to hundreds of hours of development a week."
        buttons={[
          {
            title: "Contact Us",
            isPrimary: true,
            href: "/get-a-demo",
          },
        ]}
      />
      <Section title="Enterprise features" withoutImage noMargin />
      <FeatureGrid>
        {[
          {
            title: "Dedicated autoscaling infrastructure",
            description:
              "<p>Enterprise accounts are run on a dedicated autoscaling infrastructure on AWS to deliver sub 200ms response times.</p><strong>On premises deployment coming soon.</strong>",
          },
          {
            title: "Security & Audit logs",
            description:
              "<p>We take security extremely seriously. Everything is encrypted in transit and all sensitive information is encrypted at rest.</p>",
          },
          {
            title: "Role based access control",
            description:
              "<p>Ensure cross-functional teams can collaborate by ensuring everyone has the right access.</p><strong>Detailed audit and access logs are available on request</strong>",
          },
        ]}
      </FeatureGrid>
      <Section black title="Built for the new age enterprise" noMargin />
      <FeatureGrid black>
        {[
          {
            icon: WebhooksIcon,
            title: "Webhooks & Integrations",
            description:
              "<p>Sending emails, triggering slack notifications, triggering deploys and configuring identity options are all easier than ever.</p> <strong>No coding experience needed</strong>",
          },
          {
            icon: BusinessLogicIcon,
            title: "Business Logic ++",
            description:
              "<p>Maintenance and tech debt can easily become a big distraction when dealing with production environments.</p> <strong>Canonic helps shave hours of updating, maintaining & scaling repeatable code.</strong>",
          },
          {
            icon: UnifiedDeploymentsIcon,
            title: "Unified Deployments",
            description:
              "<p>We support integration with nearly all modern stacks.</p> <strong>Use Canonic as a unified content store for all your users regardless of their device.</strong>",
          },
          {
            icon: CraftedCMSIcon,
            title: "Crafted CMS",
            description:
              "<p>Our content management system adapts to the content you define. </p><strong>Rich input types make authoring and publishing a breeze.</strong>",
          },
          {
            icon: PlaygroundIcon,
            title: "GraphQL APIs",
            description:
              "<p>GraphQL allows you to <strong>fetch only the content you need</strong> all through a <strong>single endpoint.</strong> </p><p>This makes it fast and efficient for all kinds of workloads.</p>",
          },
          {
            icon: I18nIcon,
            title: "i18n",
            description:
              "<p>Support multiple locales to serve a wider range of visitors across different languagaes and regions.</p><strong>Seamlessly add content in various languages through the CMS.</strong>",
          },
        ]}
      </FeatureGrid>
      <Section title="Secure by design" withoutImage noMargin lightBlue />
      <Cards
        lightBlue
        className={styles.cards}
        cardClassName={styles.card}
        items={[
          {
            title: "Deployed worldwide on a scalable & robust infrastructure.",
            subtitle:
              "Deployed on ISO 27001 certified data center across the world. <strong>We use AWS & Digital Ocean for our infrastructure.</strong>",
          },
          {
            title: "Role based authentication and governance.",
            subtitle:
              "Designate roles and permissions for your team members, and allow collaboration in a secure and compliant manner.",
          },
          {
            title: "Encryption at rest combined with frequent backups",
            subtitle:
              "All data is encrypted in transit with industry standard SSL. Sensitive data is encrypted at rest along with frequent backups.",
          },
        ]}
      />
      <Callout location={location} title="enterprise" href="/get-a-demo" />
    </Layout>
  )
}

export default Enterprise
